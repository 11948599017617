import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ProductsStyles } from "./ProductsStyles"
import Product from "./Product"

const Features = () => {
  return (
    <ProductsStyles>
      <div className="features__container">
        <div className="features__container--scroll">
          <p>stuff</p>
        </div>
      </div>
    </ProductsStyles>
  )
}

export default Features
